<!-- Footer -->
<!-- <p class="clearfix mb-0">
  <span class="float-md-left d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }}
    <a class="ml-25" href="https://1.envato.market/pixinvent_portfolio" target="_blank">Pixinvent</a>
    <span class="d-none d-sm-inline-block">, All rights Reserved</span>
  </span>
  <span class="float-md-right d-none d-md-block">
    Hand-crafted & Made with <span [data-feather]="'heart'" [class]="'pink'"></span>
  </span>
</p> -->
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

<!-- Buynow Button-->
<!-- <div class="buy-now">
  <a href="https://1.envato.market/vuexy_admin" target="_blank" class="btn btn-danger" *ngIf="coreConfig.layout.buyNow"
    >Buy Now</a
  >
</div> -->
