import { CoreMenu } from '@core/types';

export const menu: CoreMenu[] = [
    {
        id: 'account',
        title: 'Account',
        translate: 'Tài khoản',
        type: 'section',
        children: [
            {
                id: 'user',
                title: 'User',
                translate: 'Học sinh',
                type: 'item',
                icon: 'graduation-cap',
                url: 'user',
            },
            {
                id: 'group',
                title: 'Group',
                translate: 'Nhóm',
                type: 'item',
                icon: 'users-round',
                url: 'group',
            },
        ],
    },
    {
        id: 'question',
        title: 'Question',
        translate: 'Ngân hàng câu hỏi',
        type: 'section',
        children: [
            {
                id: 'question-manager',
                title: 'Question store',
                translate: 'Kho câu hỏi',
                type: 'item',
                icon: 'archive',
                url: 'question/store',
            },
        ],
    },
    {
        id: 'exam',
        title: 'Exam',
        translate: 'Kỳ thi',
        type: 'section',
        children: [
            {
                id: 'exam-list',
                title: 'Exam List',
                translate: 'Quản lý kỳ thi',
                type: 'item',
                icon: 'clipboard-list',
                url: 'exam',
            },
        ],
    },
    {
        id: 'course',
        title: 'Course',
        translate: 'Khóa học',
        type: 'section',
        children: [
            {
                id: 'course-list',
                title: 'Course List',
                translate: 'Quản lý khóa học',
                type: 'item',
                icon: 'school',
                url: 'course',
            },
        ],
    },
];
