import { Injectable } from '@angular/core';
import { OrganizationCheckResponse, OrganizationPrivateService } from '../auto_api/auth_private_v1';

@Injectable({ providedIn: 'root' })
export class OrganizationService {
    public orgObj?: OrganizationCheckResponse;

	constructor(
        private organizationPrivateService: OrganizationPrivateService
    ) {}
    
    public getOrgObj(): Promise<void> {
        return new Promise((resolve) => {
            if (this.orgObj) return resolve();
            const domain = `${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`
            this.organizationPrivateService.apiPrivateOrganizationGet(domain).subscribe((response) => {
                this.orgObj = response;
                resolve();
            });
        })
    }
}