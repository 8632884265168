import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { CoreModule } from '@core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { coreConfig } from 'app/app-config';
import { AppComponent } from 'app/app.component';
import { BASE_PATH as AUTH_PRIVATE_API, ApiModule as AuthPrivateApiModule } from 'app/core/auto_api/auth_private_v1';
import { BASE_PATH as AUTH_PUBLIC_API, ApiModule as AuthPublicApiModule } from 'app/core/auto_api/auth_public_v1';
import { BASE_PATH as COURSE_PRIVATE_API, ApiModule as CoursePrivateApiModule } from 'app/core/auto_api/course_private_v1';
import { BASE_PATH as COURSE_PUBLIC_API, ApiModule as CoursePublicApiModule } from 'app/core/auto_api/course_public_v1';
import { BASE_PATH as EXAM_PRIVATE_API, ApiModule as ExamApiModule } from 'app/core/auto_api/exam_private_v1';
import { BASE_PATH as EXAM_STUDENT_PUBLIC_API, ApiModule as ExamStudentApiModule } from 'app/core/auto_api/exam_student_public_v1';
import { BASE_PATH as PARSER_PUBLIC_API, ApiModule as ParserApiModule } from 'app/core/auto_api/parser_v1';
import { BASE_PATH as QUESTION_PRIVATE_API, ApiModule as QuestionApiModule } from 'app/core/auto_api/question_private_v1';
import { BASE_PATH as QUESTION_PUBLIC_API, ApiModule as QuestionPublicApiModule } from 'app/core/auto_api/question_public_v1';
import { BASE_PATH as STORE_PUBLIC_API, ApiModule as StoreApiModule } from 'app/core/auto_api/store_public_v1';
import { LayoutModule } from 'app/layout/layout.module';
import { environment } from 'environments/environment';
import 'hammerjs';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { ResponseInterceptor } from './core/interceptors/response_interceptor';
import { TokenInterceptor } from './core/interceptors/token_interceptor';

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		HttpClientModule,
		AppRoutingModule,
		TranslateModule.forRoot(),

		//NgBootstrap
		NgbModule,
		ToastrModule.forRoot(),

		// Core modules
		CoreModule.forRoot(coreConfig),
		CoreCommonModule,
		CoreSidebarModule,
		CoreThemeCustomizerModule,

		// App modules
		LayoutModule,

		//API
		AuthPrivateApiModule,
		QuestionApiModule,
		ExamApiModule,
		ExamStudentApiModule,
		StoreApiModule,
		AuthPublicApiModule,
		QuestionPublicApiModule,
		ParserApiModule,
		CoursePublicApiModule,
		CoursePrivateApiModule,
	],
	providers: [
		{ provide: AUTH_PRIVATE_API, useValue: environment.apiAuthUrl },
		{ provide: AUTH_PUBLIC_API, useValue: environment.apiAuthUrl },
		{ provide: QUESTION_PRIVATE_API, useValue: environment.apiQuestionUrl },
		{ provide: QUESTION_PUBLIC_API, useValue: environment.apiQuestionUrl },
		{ provide: EXAM_PRIVATE_API, useValue: environment.apiExamUrl },
		{ provide: EXAM_STUDENT_PUBLIC_API, useValue: environment.apiExamUrl },
		{ provide: STORE_PUBLIC_API, useValue: environment.apiStoreUrl },
		{ provide: PARSER_PUBLIC_API, useValue: environment.parserUrl },
		{ provide: COURSE_PUBLIC_API, useValue: environment.apiCourseUrl },
		{ provide: COURSE_PRIVATE_API, useValue: environment.apiCourseUrl },
		{ provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
