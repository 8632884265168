import { Component, HostBinding, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { CoreConfigService } from '@core/services/config.service';
import { CoreMediaService } from '@core/services/media.service';

import { Router } from '@angular/router';
import { User } from 'app/core/auto_api/auth_private_v1';
import { AuthUserClient, UserService } from 'app/core/services/user.service';

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent implements OnInit, OnDestroy {
	public horizontalMenu: boolean;
	public hiddenMenu: boolean;

	public coreConfig: any;
	public currentSkin: string;
	public prevSkin: string;

	public currentUser: AuthUserClient;

	public languageOptions: any;
	public navigation: any;
	public selectedLanguage: any;

	@HostBinding('class.fixed-top')
	public isFixed = false;

	@HostBinding('class.navbar-static-style-on-scroll')
	public windowScrolled = false;
	@HostListener('window:scroll', [])
	onWindowScroll() {
		if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop > 100) && this.coreConfig.layout.navbar.type == 'navbar-static-top' && this.coreConfig.layout.type == 'horizontal') {
			this.windowScrolled = true;
		} else if ((this.windowScrolled && window.pageYOffset) || document.documentElement.scrollTop || document.body.scrollTop < 10) {
			this.windowScrolled = false;
		}
	}

	private _unsubscribeAll: Subject<any>;
	constructor(
    private _router: Router, 
    private _coreConfigService: CoreConfigService, 
    private _coreMediaService: CoreMediaService, 
    private _coreSidebarService: CoreSidebarService, 
    private _mediaObserver: MediaObserver, 
    public _translateService: TranslateService, 
    private userService: UserService
  ) {
		this._unsubscribeAll = new Subject();
	}
	toggleSidebar(key): void {
		this._coreSidebarService.getSidebarRegistry(key).toggleOpen();
	}
	setLanguage(language): void {
		this.selectedLanguage = language;
		this._translateService.use(language);
		this._coreConfigService.setConfig({ app: { appLanguage: language } }, { emitEvent: true });
	}
	toggleDarkSkin() {
		this._coreConfigService
			.getConfig()
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe((config) => {
				this.currentSkin = config.layout.skin;
			});
		this.prevSkin = localStorage.getItem('prevSkin');

		if (this.currentSkin === 'dark') {
			this._coreConfigService.setConfig({ layout: { skin: this.prevSkin ? this.prevSkin : 'default' } }, { emitEvent: true });
		} else {
			localStorage.setItem('prevSkin', this.currentSkin);
			this._coreConfigService.setConfig({ layout: { skin: 'dark' } }, { emitEvent: true });
		}
	}

	logout() {
    	this.userService.logout();
		this._router.navigate(['/auth/login']);
	}
	ngOnInit(): void {
		this.currentUser  =this.userService.getUserObj();
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.coreConfig = config;
			this.horizontalMenu = config.layout.type === 'horizontal';
			this.hiddenMenu = config.layout.menu.hidden === true;
			this.currentSkin = config.layout.skin;
			if (this.coreConfig.layout.type === 'vertical') {
				setTimeout(() => {
					if (this.coreConfig.layout.navbar.type === 'fixed-top') {
						this.isFixed = true;
					}
				}, 0);
			}
		});

		if (this.coreConfig.layout.type == 'horizontal') {
			this._coreMediaService.onMediaUpdate.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
				const isFixedTop = this._mediaObserver.isActive('bs-gt-xl');
				if (isFixedTop) {
					this.isFixed = false;
				} else {
					this.isFixed = true;
				}
			});
		}

		this.selectedLanguage = _.find(this.languageOptions, {
			id: this._translateService.currentLang,
		});
	}
	ngOnDestroy(): void {
		this._unsubscribeAll.next();
		this._unsubscribeAll.complete();
	}
}
