import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule } from '@core/components';

import { ContentModule } from 'app/layout/components/content/content.module';
import { FooterModule } from 'app/layout/components/footer/footer.module';
import { MenuModule } from 'app/layout/components/menu/menu.module';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CdnPipeModule } from 'app/core/pipes/cdn/cdn.pipe.module';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { HorizontalStudentComponent } from './horizontal-student.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false,
};
@NgModule({
    declarations: [HorizontalStudentComponent],
    imports: [RouterModule, CoreCommonModule, CoreSidebarModule, NavbarModule, MenuModule, ContentModule, FooterModule, NgbModule, PerfectScrollbarModule, CdnPipeModule],
    exports: [HorizontalStudentComponent],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
    ],
})
export class HorizontalStudentModule {}
