import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {CommonService} from '../services/common.service';
import {RedirectService} from '../services/redirect.service';
import {UserService} from '../services/user.service';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
    constructor(
        private commonService: CommonService,
        private redirectService: RedirectService,
        private userService: UserService,
    ) {}
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.commonService.checkStartWithApi(request.url)) {
            return next.handle(request).pipe(
                map(event => {
                    if (event instanceof HttpResponse) {
                        let bodyData = event.body;
                        this.modifyBody(request.urlWithParams, bodyData);
                        if (event instanceof HttpResponse) {
                            event = event.clone({body: this.modifyBody(request.urlWithParams, event.body)});
                        }
                        return event;
                    }
                    return event;
                }),
                catchError((error: HttpErrorResponse) => {
                    let isThrowException = this.commonService.checkStartWithApi(request.url);
                    return this.handleError(isThrowException, true, error);
                }),
            );
        } else {
            return next.handle(request);
        }
    }
    private modifyBody(url: string, response: any): void {
        if (response && response.success == 0 && response.code == 401) {
            this.userService.logout();
            this.redirectService.login();
        } else {
            if (response && response.accountId && response.rememberToken) {
                this.userService.saveUserObj(response, response.rememberToken);
            }
        }
    }
    replaceApiDomain(message: string): string {
        return message.replace(environment.apiAuthUrl, '').replace(environment.apiQuestionUrl, '').replace(environment.apiExamUrl, '');
    }
    private handleError(ownApi: boolean, p0: boolean, error: HttpErrorResponse): Observable<any> {
        var data = {};
        if (error && error.status == 401 && ownApi) {
            data = {code: 401, data: null, detailMessage: '', message: 'lang_cms_error_unauthen', new_user: null, success: 0, version: ''};
            this.userService.logout();
            this.redirectService.login();
        } else {
            if (error.error instanceof ErrorEvent) {
                data = {
                    code: 999,
                    data: null,
                    detailMessage: '',
                    message: 'lang_cms_error_javascript' + error.status + '|' + this.replaceApiDomain(error.message).substring(0, 150),
                    new_user: null,
                    success: 0,
                    version: '',
                };
            } else {
                data = {
                    code: 1000,
                    data: null,
                    detailMessage: '',
                    message: 'lang_cms_error_lost_connect_to_server' + error.status + '|' + this.replaceApiDomain(error.message).substring(0, 150),
                    new_user: null,
                    success: 0,
                    version: '',
                };
            }
        }
        return of(
            new HttpResponse({
                status: 200,
                body: data,
            }),
        );
    }
}
