import { Injectable } from '@angular/core';
import { Account, AuthLoginResponse, AuthPrivateService, Organization, Role, UserHasPermission, UserHasRole } from '../auto_api/auth_private_v1';
import { BrowserStorageService } from './browser.storage.service';

@Injectable({ providedIn: 'root' })
export class UserService {
	tokenSave: string = '';
	private auth?: AuthUserClient;
	constructor(
		private browserStorageService: BrowserStorageService,
		private authPrivateService: AuthPrivateService
	) { }
	logout(): void {
		this.auth = undefined;
		this.tokenSave = '';
		this.browserStorageService.removeStorage('user_obj');
		this.browserStorageService.removeStorage('user_token');
	}

	syncInfoAuthLogin() {
		this.authPrivateService.apiPrivateAuthMeGet().subscribe((response) => {
			const user = this.getUserObj();
            user.fullName = response.fullName;
            user.avatar = response.avatar;
            this.saveUserObj(user);
		});
	}

	saveUserObj(userObj: AuthLoginResponse, token?: string): void {
		this.browserStorageService.saveStorage('user_obj', JSON.stringify(userObj));
		if (token) this.setToken(token);
	}

	getUserObj(): AuthUserClient | undefined {
		if (this.auth?.id) return this.auth;
		const user = this.browserStorageService.getUserObj();
		const auth = new AuthUserClient();
		for (const key in user) {
			if (Object.prototype.hasOwnProperty.call(user, key)) {
				auth[key] = user[key];
			}
		}

		this.auth = auth;

		return this.auth;
	}

	//Get info
	getToken(): string {
		const tokenTest = this.browserStorageService.getStorage('user_token');
		return tokenTest ?? '';
	}

	setToken(token: string): void {
		this.browserStorageService.saveStorage('user_token', token);
	}

	isLogin(): boolean {
		return this.browserStorageService.getUserObj() !== undefined;
	}
}

export class AuthUserClient implements AuthLoginResponse {
	id: number;
	code?: string;
	organizationId: number;
	avatar?: string;
	fullName?: string;
	birthday?: Date;
	gender?: number;
	address?: string;
	nation?: string;
	religion?: string;
	phone?: string;
	creatorId: number;
	createdAt: Date;
	updatedAt: Date;
	organization?: Organization;
	accounts?: Account[];
	userHasPermissions?: UserHasPermission[];
	userHasRoles?: UserHasRole[];
	accountId: number;
	accountType?: string;
	accountUsername?: string;
	rememberToken?: string;

	getAvatar(): string {
		return this.avatar ?? '/assets/images/avatars/default.png';
	}

	get roles(): Role[] {
		return this.userHasRoles?.map(m => m.role);
	}

	get isAdministrator(): boolean {
		return this.roles?.map(m => m.key)?.includes('admin');
	}

	get isStudent(): boolean {
		return this.roles?.map(m => m.key)?.includes('student');
	}
}
