<nav class="header-navbar navbar-expand-lg navbar navbar-fixed card rounded-0 align-items-center navbar-shadow navbar-brand-center" data-nav="brand-center" *ngIf="isShowLayout">
    <div class="navbar-container w-100 d-flex content align-items-center">
        <div class="bookmark-wrapper d-flex align-items-center">
            <ul class="nav navbar-nav d-xl-none">
                <li class="nav-item">
                    <a class="nav-link menu-toggle" (click)="toggleSidebar()">
                        <i class="ficon" data-feather="menu"></i>
                    </a>
                </li>
            </ul>
            <ul class="nav navbar-nav ml-50">
                <a class="navbar-brand d-flex align-items-center flex-nowrap" routerLink="/student">
                    <span class="brand-logo">
                        <img src="https://100study.vn/uploaded/299704150_2218037298359549_5326542185267898863_n.png" alt="brand-logo" height="36" />
                    </span>
                </a>
            </ul>
        </div>
        <div class="d-none d-md-block ml-auto">
            <ul class="nav navbar-nav" id="main-menu-navigation" data-menu="menu-navigation">
                <li class="dropdown nav-item sidebar-group-active ml-2" data-menu="dropdown">
                    <a routerLinkActive="text-primary" class="dropdown-toggle nav-link d-flex align-items-center" routerLink="/student/dashboard">
                        <span class="font-weight-bold">Trang chủ</span>
                    </a>
                </li>
                <li class="dropdown nav-item sidebar-group-active ml-2" data-menu="dropdown" routerLink="/student/exam-list">
                    <a routerLinkActive="text-primary" class="dropdown-toggle nav-link d-flex align-items-center">
                        <span class="font-weight-bold">{{ 'Đề thi của tôi' }}</span>
                    </a>
                </li>
                <li class="dropdown nav-item sidebar-group-active ml-2" data-menu="dropdown" routerLink="/student/course-list/1">
                    <a routerLinkActive="text-primary" class="dropdown-toggle nav-link d-flex align-items-center">
                        <span class="font-weight-bold">{{ 'Khóa học' }}</span>
                    </a>
                </li>
                <li class="dropdown nav-item sidebar-group-active ml-2" data-menu="dropdown" routerLink="/student/histories">
                    <a routerLinkActive="text-primary" class="dropdown-toggle nav-link d-flex align-items-center">
                        <span class="font-weight-bold">Lịch sử học tập</span>
                    </a>
                </li>
            </ul>
        </div>
        <ul class="nav navbar-nav align-items-center ml-auto ml-md-1">
            <li ngbDropdown class="nav-item dropdown-user">
                <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="avatar">
                        <img class="round" [src]="currentUser.getAvatar() | cdnUrl" alt="avatar" height="40" width="40" />
                        <span class="avatar-status-online"></span>
                    </span>
                </a>
                <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
                    <a ngbDropdownItem [routerLink]="['/manager/profile']">
                        <span [data-feather]="'user'" [class]="'mr-50'"></span>
                        Tài khoản
                    </a>
                    <div class="dropdown-divider" *ngIf="currentUser.isAdministrator"></div>
                    <a ngbDropdownItem [routerLink]="['/manager']" *ngIf="currentUser.isAdministrator">
                        <span [data-feather]="'key'" [class]="'mr-50'"></span>
                        Administrator
                    </a>
                    <div class="dropdown-divider"></div>
                    <a ngbDropdownItem (click)="logout()">
                        <span [data-feather]="'power'" [class]="'mr-50'"></span>
                        Đăng xuất
                    </a>
                </div>
            </li>
            <!--/ User Dropdown -->
        </ul>
    </div>
</nav>
<div class="app-content-student" [ngClass]="{ ' px-50 px-md-2 pt-0': isShowLayout, 'p-0': !isShowLayout }">
    <div class="content-overlay"></div>
    <!-- CONTENT -->
    <router-outlet></router-outlet>
    <!-- <router-outlet *ngIf="organizationService.orgObj"></router-outlet> -->
    <!--/ CONTENT -->
</div>
<core-sidebar
    name="menu"
    [collapsed]="coreConfig.layout.menu.collapsed"
    collapsibleSidebar="bs-gt-xl"
    *ngIf="!coreConfig.layout.menu.hidden"
    class="main-menu menu-fixed menu-accordio menu-shadow block d-md-none"
    overlayClass="sidenav-overlay"
    [ngClass]="[coreConfig.layout.skin === 'semi-dark' || coreConfig.layout.skin === 'dark' ? 'menu-dark' : 'menu-light', coreConfig.layout.menu.collapsed ? '' : 'expanded']">
    <div class="vertical-menu card rounded-0 h-100">
        <div class="navbar-header">
            <ul class="nav navbar-nav flex-row">
                <li class="nav-item mr-auto">
                    <a class="navbar-brand d-flex align-items-center flex-nowrap" routerLink="/student">
                        <img src="https://100study.vn/uploaded/299704150_2218037298359549_5326542185267898863_n.png" alt="brand-logo" style="width: 100px" />
                    </a>
                </li>
                <li class="nav-item nav-toggle">
                    <a class="nav-link modern-nav-toggle d-block d-xl-none pr-0" (click)="toggleSidebar()">
                        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
                    </a>
                </li>
            </ul>
        </div>
        <div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>
        <div class="main-menu-content" [perfectScrollbar] (scroll)="onSidebarScroll()">
            <ul class="navigation navigation-main">
                <li>
                    <a class="d-flex align-items-center" routerLink="/student">
                        <span class="menu-title text-truncate">Trang chủ</span>
                    </a>
                </li>
                <li>
                    <a class="d-flex align-items-center" routerLink="/student/histories">
                        <span class="menu-title text-truncate">Lịch sử làm bài</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</core-sidebar>
